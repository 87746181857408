import { deleteData, getData, setData } from "./storageService";

export const clearSession = (): void => {
  deleteData();
};

type Session = {
  token?: string;
  Role?: string;
  transactionId?: string | any;
  userName?: string;
};

export const addSession = (session: Session): void => {
  if (session.transactionId) {
    setData("transactionId", session.transactionId);
    return;
  }
  setData("isLoggedIn", true);
  setData(`Token`, session.token);
  setData("role", session.Role);
  setData("userName", session.userName);
};

export const checkIfLogin = (): boolean => !!getData("isLoggedIn") || false;

export const getAuthHeader = (): object | string | null => getData(`Token`);

export const getRole = (): string[] => getData("role");
export const getUserName = (): string => getData("userName");

export const getPaypalInitiateTransactionId = ():
  | object
  | string
  | string[]
  | null => getData("transactionId");
