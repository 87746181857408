import { getRole } from "../../utils/sessionManagement";

const Role: any = {
  admin: [
    "/settings",
    "/transactions",
    "/payment",
    "Directories",
    "Past Transactions",
    "Settings",
    "/apiCredentials",
    "apiCredentials",
    "Approvebtn",
    "/summary",
  ],
  user: [
    "/transactions",
    "/payment",
    "Directories",
    "Past Transactions",
    "Settings",
    "/settings",
    "Approvebtn",
    "/summary",
  ],
  merchant: [
    "/settings",
    "Directories",
    "Settings",
    "/apiCredentials",
    "apiCredentials",
  ],
};

export function visibleToRole(component: string): boolean {
  const n = Object.keys(Role).length;
  const ro: any = getRole();

  try {
    for (const r of ro) {
      for (let i = 0; i < n; i++) {
        if (Object.keys(Role)[i] === r) {
          if (Role[Object.keys(Role)[i]].includes(component)) {
            return true;
          }
        }
      }
    }
    return false;
  } catch (error) {
    console.error();
    return false;
  }
}
