import { ApiConfig, ApiState } from "./../../index.d";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils";
import {
  setFulFillState,
  setPendingState,
  setRejectedState,
} from "../../utils/setStateUtils";

export interface FormFields {
  search: string;
  date?: string;
}

const API_CONFIG: ApiConfig = {
  GET_PROJECT_LIST: {
    url: "/directory",
    method: "GET",
    data: {},
  },
  DIRECTORIES_COUNT: {
    url: "/directory/count",
    method: "GET",
    data: {},
  },
};

const initialState: ApiState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
  flag: false,
};

export const ProjectListApiCall = createAsyncThunk(
  "get/directory",
  async (getProjectListParam: string, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.GET_PROJECT_LIST };
      apiPayload.url += `?${getProjectListParam}`;
      console.log("getProjectListParam", apiPayload);
      const response = await apiClient(apiPayload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const projectListSlice = createSlice({
  name: "directory",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ProjectListApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(ProjectListApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(ProjectListApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

const paymentModeSlice = createSlice({
  name: "paymentMode",
  initialState: initialState,
  reducers: {
    setPaymentMode(state, action) {
      state.data = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(ProjectListApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(ProjectListApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(ProjectListApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

export const directoriesCountApiCall = createAsyncThunk(
  "get/directoriesCount",
  async (_, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.DIRECTORIES_COUNT };
      const response = await apiClient(apiPayload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const directoriesCountSlice = createSlice({
  name: "directoriesCount",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(directoriesCountApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(directoriesCountApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(directoriesCountApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

export const { setPaymentMode } = paymentModeSlice.actions;
export const projectListAction = projectListSlice.actions;
export const paymentModeAction = paymentModeSlice.actions;
export const directoryCountAction = directoriesCountSlice.actions;

const projectListReducer = {
  projectListData: projectListSlice.reducer,
  paymentMode: paymentModeSlice.reducer,
  directoriesCount: directoriesCountSlice.reducer,
};
export default projectListReducer;
