import { ApiConfig, ApiState } from "./../../index.d";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils";
import {
  setFulFillState,
  setPendingState,
  setRejectedState,
} from "../../utils/setStateUtils";

export interface FormFields {
  search: string;
  date?: string;
}

const API_CONFIG: ApiConfig = {
  GET_API_KEY: {
    url: "/users/me/api_key",
    method: "GET",
    data: {},
  },
  UPDATE_API_KEY: {
    url: "/users/me/api_key",
    method: "PUT",
    data: {},
  },
  CREATE_CLIENT_KEY_MERCHANT: {
    url: "/credentials/create",
    method: "POST",
    data: {},
  },
  GET_CLIENT_KEY_MERCHANT: {
    url: "/credentials",
    method: "GET",
    data: {},
  },
  UPDATE_CLIENT_KEY_MERCHANT: {
    url: "/users/me/api_key",
    method: "PUT",
    data: {},
  },
};

const initialState: ApiState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
  flag: false,
};

const updateApiKeyinitialState: ApiState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
  flag: false,
};

export const generateKeyApiCall = createAsyncThunk(
  "get/generateKey",
  async (_getProjectListParam: string, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.GET_API_KEY };
      return await apiClient(apiPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateKeyApiCall = createAsyncThunk(
  "put/updateKey",
  async (_getProjectListParam: string, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.UPDATE_API_KEY };
      return await apiClient(apiPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMerchantKeyApiCall = createAsyncThunk(
  "post/createMerchantKey",
  async (getProjectListParam: any, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.CREATE_CLIENT_KEY_MERCHANT };
      apiPayload.data = getProjectListParam;
      return await apiClient(apiPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMerchantKeyApiCall = createAsyncThunk(
  "get/getMerchantKey",
  async (_getProjectListParam: string, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.GET_CLIENT_KEY_MERCHANT };
      return await apiClient(apiPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMerchantKeyApiCall = createAsyncThunk(
  "put/updateMerchantKey",
  async (_getProjectListParam: string, { rejectWithValue }) => {
    try {
      const apiPayload = { ...API_CONFIG.UPDATE_CLIENT_KEY_MERCHANT };
      return await apiClient(apiPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const generateKeySlice = createSlice({
  name: "generateKey",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateKeyApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(generateKeyApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(generateKeyApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

const updateKeySlice = createSlice({
  name: "updateKey",
  initialState: updateApiKeyinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateKeyApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(updateKeyApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(updateKeyApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

const createMerchantKeySlice = createSlice({
  name: "createMerchantKey",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createMerchantKeyApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(createMerchantKeyApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(createMerchantKeyApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

const getMerchantKeySlice = createSlice({
  name: "getMerchantKey",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMerchantKeyApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(getMerchantKeyApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(getMerchantKeyApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

const updateMerchantKeySlice = createSlice({
  name: "updateMerchantKey",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateMerchantKeyApiCall.pending, (state) => {
        setPendingState(state);
      })
      .addCase(updateMerchantKeyApiCall.fulfilled, (state, action) => {
        setFulFillState(state, action);
      })
      .addCase(updateMerchantKeyApiCall.rejected, (state, action) => {
        setRejectedState(state, action);
      });
  },
});

export const generateKeyAction = generateKeySlice.actions;
export const updateKeyAction = updateKeySlice.actions;
export const createMerchantKeyAction = createMerchantKeySlice.actions;
export const getMerchantKeyAction = getMerchantKeySlice.actions;
export const updateMerchantKeyAction = updateMerchantKeySlice.actions;

const generateKeyReducer = {
  generateKeyData: generateKeySlice.reducer,
  updateKeyData: updateKeySlice.reducer,
  createMerchantKeyData: createMerchantKeySlice.reducer,
  getMerchantKeyData: getMerchantKeySlice.reducer,
  updateMerchantKeyData: updateMerchantKeySlice.reducer,
};
export default generateKeyReducer;
